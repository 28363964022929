<template>
  <div class="home">
    <SideBar :setHeader="(value) => setHeader(value)"></SideBar>
    <div 
      v-if="heightHeader != 0 && heightTotal != 0" 
      :style="`
        height: ${heightTotal - (heightHeader + 1)}px; 
        width: 100%; 
        background-color: transparent; 
        overflow: auto; 
        margin-top: ${heightHeader}px;`">
      <nav>
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
      </nav>
      <router-view/>
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/sideBar/SideBar.vue'
export default {
  name: 'HomeView',
  components: {
    SideBar,
  },
  data() {
    return {
      heightHeader: 0,
      heightTotal: 0,
    };
  },
  mounted () {
    this.getHeightTotal(document.documentElement.clientHeight)
  },
  methods: {
    getHeightTotal(valueHeight) {
      this.heightTotal = valueHeight
    },
    setHeader(valueHeight) {
      this.heightHeader = valueHeight
    },
  },
};
</script>
<style scoped>
.home {
  padding: 0px;
  margin: 0px;
  overflow: hidden;
}
p {
  padding: 0;
  margin: 0;
}
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(0, 0, 0); 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: red; 
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}
</style>
import { createApp } from 'vue'
import App from './App.vue'
import AddToHomescreen from '@owliehq/vue-addtohomescreen';
import './registerServiceWorker'
import router from './router'
import store from './store'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

createApp(App).use(store).use(router).use(AddToHomescreen, {
    buttonColor: 'blue',
  }).mount('#app')

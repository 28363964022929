<template>
  <div class="offcanvas offcanvas-start background-sidebar" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-body">
      <img alt="Vue logo" src="../../assets/logo/logo_sinarmas_agri.png" style="width: 80%;">
    </div>
  </div>
  <!-- Header Menu Mas Brow -->
   <div class="header-menu row" ref="infoBox">
    <div class="col-1 col-md-1 col-xl-1" style="text-align: center;">
      <img 
        data-bs-toggle="offcanvas" 
        href="#offcanvasExample" 
        role="button" 
        aria-controls="offcanvasExample" 
        alt="Vue logo" 
        src="../../assets/icon/icon_burger_menu.png" 
        style="width: 20px;">
    </div>
    <div class="col-10 col-md-5 col-xl-5 row div-border">
      <div class="col-2" style="text-align: center;">
        <img 
          alt="icon search" 
          src="../../assets/icon/icon_search.png" 
          style="width: 20px;">
      </div>
      <div class="col-10" style="padding-right: 20px;">
        <input 
          type="text" 
          class="form-control" 
          placeholder="Search">
      </div>
    </div>
    <div class="col-1 col-md-1 col-xl-2"></div>
    <div class="col-6 col-md-2 col-xl-2">
      <div class="row" style="margin: 5px">
        <div class="col-10" style="text-align: right;">
          <img 
            alt="icon dot green" 
            src="../../assets/icon/icon_help.png" 
            style="width: 25px;">
          <span style="padding-left: 10px;">Bantuan</span>
        </div>
        <div class="col-2" style="text-align: center;">
          <img 
            alt="icon dot green" 
            src="../../assets/icon/icon_notifications.png" 
            style="width: 25px;">
        </div>
      </div>
    </div>
    <div class="col-6 col-md-3 col-xl-2">
      <div class="div-border row" style="margin: 10px; align-items: center; padding: 5px; text-align: center;">
        <div class="col-2">
          <img 
            alt="icon dot green" 
            src="../../assets/icon/icon_dot_green.png" 
            style="width: 10px; vertical-align: baseline;">
        </div>
        <div class="col-8">
          <span class="col-8">Jhon Doe ... </span> 
        </div>
        <div class="col-2">
          <img 
            alt="icon drop down" 
            src="../../assets/icon/icon_down.png" 
            style="width: 20px;">
        </div>
      </div>
    </div>
   </div>
   <!-- <slot></slot> -->
</template>

<script>
export default {
  name: 'SideBar',
  props: {
    setHeader: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      displayMenu: true,
    };
  },
  methods: {
    changeMenu() {
      this.displayMenu = !this.displayMenu
    },
    matchHeight () {
      this.setHeader(this.$refs.infoBox.clientHeight)
      // console.log(this.$refs.infoBox.clientHeight)
    }
  },
  mounted () {
   this.matchHeight()
  },
};
</script>

<style scoped>
.div-border {
  border: 2px solid grey; 
  border-radius: 100px;
}
.row {
  --bs-gutter-x: 0px;
  align-items: center;
}
input {
  border: 0px solid transparent;
  background-color: transparent;
  padding-left: 0px;
  padding-right: 5px;
}
input:focus, input.form-control:focus {
  border: 0px solid transparent;
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  background-color: transparent;
  padding-left: 0px;
  padding-right: 5px;
}
.header-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: white;
  width: 100%;
  height: fit-content;
  padding-bottom: 10px;
  padding-top: 10px;
  box-shadow: 2px 3px 7px 0px rgba(0,0,0,0.54);
  -webkit-box-shadow: 2px 3px 7px 0px rgba(0,0,0,0.54);
  -moz-box-shadow: 2px 3px 7px 0px rgba(0,0,0,0.54);
}
.offcanvas-body {
  width: 100%; 
  overflow: hidden; 
  text-align: center;
}
.offcanvas {
  --bs-offcanvas-width: 18%;
}
.background-sidebar {
  background-color: #414042;
  box-shadow: 6px 2px 10px -1px rgba(0,0,0,0.75);
  -webkit-box-shadow: 6px 2px 10px -1px rgba(0,0,0,0.75);
  -moz-box-shadow: 6px 2px 10px -1px rgba(0,0,0,0.75);
}
@media screen and (max-device-width: 480px) 
  and (orientation: portrait) {
  .offcanvas {
    --bs-offcanvas-width: 90%;
  }
}
</style>